<template>
  <div class="d-flex">
    <v-tooltip
      v-if="!chip"
      top
    >
      <template v-slot:activator="{on}">
        <div
          v-on="on"
          class="cursor-pointer"
        >{{arrayText}}</div>
      </template>
      <div>{{arrayText}}</div>
    </v-tooltip>

    <div class="d-flex flex-wrap">
      <v-chip
        v-for="(item, itemIndex) in dataArray"
        v-bind="chipProperty(item, itemIndex)"
        class="mr-2 mb-2"
        small
        :key="`list-data-${name}-chip-${itemIndex}`"
      >
        {{config.itemText(item)| t}}
      </v-chip>
    </div>
  </div>
</template>

<script>
import listDataItem from '@/components/list/mixins/listDataItem'
export default {
  mixins: [listDataItem],
  methods: {
    chipProperty(item, itemIndex) {
      if(typeof this.config.chipProperty != 'function') return null
      return this.config.chipProperty(this.row, item, itemIndex)
    },
  },
  computed: {
    chip() {
      return this.config.chip === true
    },
    separator() {
      return this.config.separator || ', '
    },
    dataArray() {
      if(!Array.isArray(this.itemData)) return []
      return this.itemData
    },
    arrayText() {
      if(!Array.isArray(this.itemData)) return null
      const itemTextArray = this.itemData.map(item => this.config.itemText(item))
      return window.eagleLodash.join(itemTextArray, this.separator)
    },
    textClass() {
      let textClass = []
      if(this.config.truncate != false) {
        textClass.push('text-truncate')
      }
      return textClass
    },
  }
}
</script>

<style lang="sass" type="text/sass" scoped></style>
